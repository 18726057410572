import { core, http } from 'novapay-ui';
import handleError from '@services/handle-api-error';
import commonStore from '@services/common-store';

const types = core.getTypes('main_page');
const rootTypes = core.getTypes('root');
const { actions: commonActions } = commonStore('main-page');

const getOperations = async (context) => {
	let operations = await http('/v3/operations', { query: context.state.props.query });
	if (!handleError()(operations, context)) {
		return;
	}
	let total = operations.data.length && operations.data[0].total_count;
	return context.commit(types.GET_OPERATIONS_SUCCESS, { rows: operations.data, total });
};

const getTransactions = async (context) => {
	let transactions = await http('/v3/transactions', { query: context.state.props.query });
	if (!handleError()(transactions, context)) {
		return;
	}
	let total = transactions.data?.length && transactions.data[0].total_count;
	return context.commit(types.GET_TRANSACTIONS_SUCCESS, { rows: transactions.data, total });
};

const uploadFile = async (context, file) => {
	let formData = new FormData();
	let handler = (context, res) => {
		if (res.status === 403) {
			return context.commit(rootTypes.namespaced.APP_DATA_ERROR, null, { root: true });
		}
		let err = res.data?.error || (res.data?.errors?.length && res.data?.errors[0].message);
		context.commit(types.UPLOAD_FILE_ERROR, err);
	};
	formData.append('file', file);

	let res = await http({
		url: `/v3/registry/upload`,
		method: 'POST',
		data: formData,
		headers: {
			'content-type': 'multipart/form-data'
		},
		onUploadProgress: (progress) => context.commit(types.SET_UPLOAD_PROGRESS, progress)
	});
	if (!handleError(handler)(res, context)) {
		return;
	}
	let snackbar = { variant: 'success', title: 'Реєстр додано успішно' };
	let balance = res.data?.balance || context.rootState.root.props.contragent.balance;
	context.commit(types.APPLY_QUERY, context.state.props.query);
	context.commit(rootTypes.namespaced.UPDATE_CONTRAGENT_BALANCE, balance, { root: true });
	context.commit(types.CLEAR_UPLOAD_FILE_ERROR);
	return context.commit(rootTypes.namespaced.ADD_SNACKBAR, snackbar, { root: true });
};

const exportData = async (context, data) => {
	let { table } = context.state.props;
	let options = {
		url: `/v3/${table === 'operations_table' ? 'operations' : 'transactions'}/export`,
		method: 'POST',
		data: { email: data.email, query: context.state.props.query }
	};
	let res = await http(options);
	if (!handleError()(res, context)) {
		return;
	}
	let snackbar = { variant: 'success', title: 'Експорт виконано успішно' };
	return context.commit(rootTypes.namespaced.ADD_SNACKBAR, snackbar, { root: true });
};

const printFiscalCheque = async (context, payload) => {
	let { id } = payload;
	let res = await http('/v3/operations/print-fiscal-cheque', {
		method: 'POST',
		data: { id }
	});
	if (!handleError()(res, context)) {
		return;
	}
	Object.assign(document.createElement('a'), { target: '_blank', href: res.data.cheque_url }).click();
};

const changeTab = (context, table) => {
	window.history.pushState({}, '', '/');
	localStorage.setItem('table', table);
	return context.commit(types.CHANGE_TAB, table);
};

const setActiveTab = (context) => {
	const { table } = context.state.props;
	switch (table) {
		case 'operations_table':
			return context.commit(types.SET_OPERATIONS_TABLE);
		case 'transactions_table':
			return context.commit(types.SET_TRANSACTIONS_TABLE);
		default:
			return context.commit(types.SET_OPERATIONS_TABLE);
	}
};

export default {
	...commonActions,
	setActiveTab,
	getTransactions,
	getOperations,
	changeTab,
	uploadFile,
	exportData,
	printFiscalCheque
};
